import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import { useNailArtQuery } from 'lib/contentful/__generated__/NailArtQuery';
import type { NailArtQueryVariables } from 'lib/contentful/__generated__/NailArtQuery';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import {
  getContentfulNailArtUrlCategoryFilter,
  getContentfulTagFilter,
} from 'utils/contentfulHelpers';
import { formatContentfulNailArt } from 'utils/format/nailArt';
import { usePreviewCookie } from 'utils/getPreviewCookie';
import type { StoreNailArt } from './types/storeNailArt';

export type NailArtContext = StoreNailArt & {
  isLoading: boolean;
};

export const NailArtContext = createContext<NailArtContext | undefined>(
  undefined
);

export function NailArtProvider({
  handle,
  children,
  professionalContent = false,
}: {
  handle: string;
  proCustomer?: boolean;
  children: ReactNode;
  professionalContent?: boolean;
}) {
  const { locale: routerLocale } = useRouter();

  const locale = storeLocale(routerLocale);

  const preview = usePreviewCookie();

  const variables: NailArtQueryVariables = {
    handle,
    locale,
    preview,
    tagFilter: getContentfulTagFilter(locale),
    urlCategoryFilter:
      getContentfulNailArtUrlCategoryFilter(professionalContent),
  };

  const { data, isLoading } = useQuery(
    useNailArtQuery.getKey(variables),
    useNailArtQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: preview }),
      variables
    ),
    {
      select: formatContentfulNailArt,
    }
  );

  const contextValue = useMemo(() => {
    if (data) {
      return {
        ...data,
        handle,
        isLoading,
      };
    }

    return {
      title: '',
      proOnly: false,
      handle,
      isLoading,
    };
  }, [data, isLoading]);

  return (
    <NailArtContext.Provider value={contextValue}>
      {children}
    </NailArtContext.Provider>
  );
}
